body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.dropdown-option {
  font-size: 12px;
}
.dropdown-option strong {
  color: #000;
}
.dropdown-option span {
  display: block;
  color: #807e7e;
}
.active-column
{
  margin-top: 2px;
    font-weight: 400;
    text-align: center;
    color: green;
}
.inactive-column
{
  margin-top: 2px;
    font-weight: 400;
    text-align: center;
    color: red;
}
.text-bold-500
{font-weight: 500;}

.table-cell:has(.child-p) {
  max-height:unset !important;
  display: block !important;
}
.child-p{
  line-height: 1.35em;
  padding: 0 0 1em 0;
  text-align: justify;
}